import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import fourohfour from "../assets/404.png";
import sadface from "../assets/sadface.png";

const PageNotFound = () => {
  return (
    <Box id="wrapper" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: '#c0c0c0' }}>
      <img src={fourohfour} alt="404" width="40%" />
      <Box id="info" sx={{ textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>UH OH! You're lost.</Typography>
        <Typography variant="h5" sx={{ color: '#3f51b5' }}>This page could not be found</Typography>
      </Box>
      <Box sx={{marginY:2}}>
        <img src={sadface} width="70px" alt="sadface" />
      </Box>
      <Button variant="contained" color="primary" onClick={() => window.location.href = '/'}>Back to Home</Button>
    </Box>
  )
}

export default PageNotFound
