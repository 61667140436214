import { Memory } from "@mui/icons-material";
import react from "../assets/react.svg"
import python from "../assets/python.png"
import ts from "../assets/ts.svg"
import js from "../assets/js.svg"
import jquery from "../assets/jquery.png"
import html from "../assets/html.svg"
import css from "../assets/css.svg"
import pytorch from "../assets/pytorch.svg" 
import flask from "../assets/flask.png"
import pandas from "../assets/pandas.png"
import numpy from "../assets/numpy.png"
import scikit from "../assets/scikit2.svg"
import scipy from "../assets/scipy.png"
import plt from "../assets/matplotlib.png"
import tf from "../assets/tensorflow.svg"
import csharp from "../assets/c_sharp.png"
import sql from "../assets/sql.svg"
import opencv from "../assets/opencv.svg"
import gcp from "../assets/gcp.png"
import git from "../assets/git.svg"
import kub from "../assets/kubernetes.svg"
import linux from "../assets/linux.svg"
import bash from "../assets/bash.svg"
import docker from "../assets/docker.svg"
import firebase from "../assets/firebase.png"

export default function Skills() {
  return (
    <section id="skills">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <Memory className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Skills &amp; Technologies
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi sit
            ipsa delectus eum quo voluptas aspernatur accusantium distinctio
            possimus est.
          </p>
        </div>
        
        <div className="flex flex-wrap -m-4">
          <div className="sm:w-1/3 w-100 p-4" style={{ fontWeight:"bold",fontSize:30,justifyContent:"center",alignItems:"center"}}>
              <h2 style={{textAlign:"center",display:"block"}}>FRONTEND</h2>
              <div style={{textAlign:"center",display:"block"}}>  
                <a title="React" href="https://reactjs.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={react} alt="React"/></a>  
                <a title="Typescript" href="https://www.typescriptlang.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={ts} alt="TypeScript"/></a> 
                <a title="Javascript" href="https://www.javascript.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={js} alt="JavaScript"/></a> 
                <a title="JQuery" href="https://jquery.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={jquery} alt="jQuery"/></a>  
                <a title="HTML5" href="https://en.wikipedia.org/wiki/HTML5" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={html} alt="HTML5"/></a>   
                <a title="CSS" href="https://www.w3schools.com/css/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={css} alt="CSS3"/></a>   
              </div>
          </div>
          <div className="sm:w-1/3 w-100 p-4" style={{fontWeight:"bold",fontSize:30,justifyContent:"center",alignItems:"center"}}>
            <h2 style={{textAlign:"center",display:"block"}}>BACKEND</h2>
            <div style={{textAlign:"center", display:"block"}}>   
              <a title="Python" href="https://www.python.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={python} alt="Python"/></a>  
              <a title="Pytorch" href="https://pytorch.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={pytorch} alt="pytorch"/></a>  
              <a title="Flask" href="https://flask.palletsprojects.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={flask} alt="Flask"/></a>  
              <a title="Pandas" href="https://pandas.pydata.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={pandas} alt="Flask"/></a>  
              <a title="NumPy" href="https://numpy.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={numpy} alt="NumPy"/></a>  
              <a title="Scikit Learn" href="https://scikit-learn.org/stable/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={scikit} alt="Scikit Learn"/></a>  
              <a title="scipy" href="https://scipy.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={scipy} alt="Scipy"/></a>  
              <a title="matplotlib" href="https://matplotlib.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={plt} alt="Matplotlib"/></a>  
              <a title="Tensorflow" href="https://www.tensorflow.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={tf} alt="TensorFlow"/></a>  
              <a title="Typescript" href="https://www.typescriptlang.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={ts} alt="TypeScript"/></a>  
              <a title="Javascript" href="https://www.javascript.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={js} alt="JavaScript"/></a>  
              <a title="C#" href="https://docs.microsoft.com/en-us/dotnet/csharp/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={csharp} alt="C#"/></a>  
              <a title="SQL" href="https://www.w3schools.com/sql/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={sql} alt="SQL"/></a>  
              <a title="OpenCV" href="https://opencv.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={opencv} alt="OpenCV"/></a>  
            </div>
          </div>
          <div className="sm:w-1/3 w-100 p-4" style={{fontWeight:"bold",fontSize:30,justifyContent:"center",alignItems:"center"}}>
            <h2 style={{textAlign:"center",display:"block"}}>DEVOPS</h2>
            <div style={{textAlign:"center", display:"block"}}>   
              <a title="Google Cloud Platform" href="https://cloud.google.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={gcp} alt="GCP"/></a>  
              <a title="Git" href="https://github.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={git} alt="Git"/></a>  
              <a title="Kubernetes" href="https://kubernetes.io/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={kub} alt="Kubernetes"/></a>  
              <a title="Linux" href="https://www.linux.org/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={linux} alt="Linux"/></a>  
              <a title="Bash"href="https://www.gnu.org/software/bash/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={bash} alt="Bash"/></a>  
              <a title="Docker" href="https://www.docker.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={docker} alt="Docker"/></a>  
              <a title="Firebase" href="https://firebase.google.com/" target="_blank"><img style={{margin:10,height:60,width:60,display:"inline"}} src={firebase} alt="Firebase"/></a>  
            </div> 
          </div>
          {/* {skills.map((skill:any) => (
            <div key={skill} className="p-3 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                <VerifiedIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </section>
  );
}