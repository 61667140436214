import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import UserDropDown from './UserDropDown';
import NavigationPages from './NavigationPages';
import { NavigationBarProps } from './NavigationBar.types';
import { Box, Slide } from '@mui/material';


const NavigationBar = (props: NavigationBarProps) => {
  const {hidden} = props;

  return (
    <Box position="sticky" sx={{ overflow: 'hidden', zIndex: 1000 }}>
      <Slide direction="down" in={hidden} mountOnEnter unmountOnExit easing={'ease-in-out'}>
        <AppBar  sx={{backgroundColor: '#111827', color: '#9CA3AF'}}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <NavigationPages></NavigationPages>
              <UserDropDown></UserDropDown>
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>
    </Box>
  );
}
export default NavigationBar;