import { Box } from "@mui/material";
import Navbar from '../../components/NavigationBar/NavigationBar';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from "react";
import videoSrc from '../../assets/rickroll.mp4';


const VideoPlayer: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Autoplay started
        }).catch((error: any) => {
          // Autoplay was prevented
          console.log('Autoplay was prevented', error);
        });
      }
    }
  }, []);

  return (
    <div>
      <video ref={videoRef} style={{ width: '100%', height: 'auto' }} controls autoPlay loop>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

const Wmh = () => {
    const [navbarHidden, setNavbarHidden] = useState(true);
    const [scrollPos, setScrollPos] = useState(101);
    
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const hide = scrollPos > currentScrollPos || currentScrollPos <= 250;
    
        setScrollPos(window.scrollY);
        setNavbarHidden(hide);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [scrollPos]);
    
  return (
    <Box className='bg-slate-300 min-h-screen'>
      <Navbar hidden={navbarHidden}/>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'column', 
          alignItems: 'center',
          px: 3, 
          py: 5,
          paddingTop: '110px',
          flexDirection: {
            xs: 'column',
            md: 'row'
          } }}>
          <Box>Project in works - Please check back later</Box>
          <VideoPlayer></VideoPlayer>
        </Box>
      <Footer />
    </Box>
  )
}

export default Wmh
