import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Link } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export default function PortfolioLinks() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 0.5 }}>
      <Link href="https://github.com/leslie-zi-pan" target="_blank" rel="noopener noreferrer">
        <GitHubIcon sx={{ fontSize: 32, color: '#111827', '&:hover':  { color: 'primary.main' }}} />
      </Link>
      <Link href="https://www.linkedin.com/in/leslie-pan/" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon sx={{ fontSize: 32, color: '#111827', '&:hover':  { color: 'primary.main' }}} />
      </Link>
      <Link href="/assets/Leslie_Pan_Resume.pdf" target="_blank" rel="noopener noreferrer">
        <ContactPageIcon sx={{ fontSize: 32, color: '#111827', '&:hover': { color: 'primary.main' } }}  />
      </Link>
    </Box>
  );
}
