import WorkIcon from '@mui/icons-material/Work';
import { Box, Typography, Paper } from "@mui/material";


const Experiences = () => {
    return (
        <Box sx={{
          color: 'gray',
          backgroundColor: '#111827',
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            paddingTop: '2rem', 
            paddingBottom: '2rem'
          }}>
            <WorkIcon className="mx-auto inline-block w-10 mb-4" />
              <Typography variant="h1" component="h1" color={'white'} sx={{fontSize:'3rem', marginBottom:'1rem'}} >Experience</Typography>
              <Box sx={{ width: '80%', textAlign: 'justify' }}>
                <Typography variant="h4" component="h4" >
                  Machine Learning Engineer | 2022 - Present
                </Typography>
                <Typography variant="body1" component="p" >
                  • Lead creation of data pipeline solutions using GCP tools (PubSub, Cloud functions,
                  Dataflow, Bigquery, BigLake) to streamline and log analytics and successfully
                  drive down internal operational costs
                  <br></br>
                  • Develop ML solutions using BigQuery and Vertex AI for patient analytics
                  <br></br>
                  • Develop speech-to-text body chart selector tool to aide visually impaired users
                  <br></br>
                  • Re-engineered an existing chatbot prototype using Bayes Inference for muscular
                  skeletal diagnosis. Led modularization, authentication, and testing efforts to
                  ensure seamless integration and optimal performance of the chatbot, and
                  deployed it as a microservice on GCP for clinicians and stakeholders
                  <br></br>
                  • Operate under agile methodology with exposure to React.ts under CI/CD to
                  existing chatbot software
                </Typography>
              </Box>
              <Box sx={{ width: '80%', textAlign: 'justify', mt: 2 }}>
                <Typography variant="h4" component="h4" >
                  Software Engineer | 2019 - 2021
                </Typography>
                <Typography>
                • Full-stack developer with a proven track record of delivering bespoke intranet
                solutions using ASP.NET (C#), HTML5, SQL Server, JavaScript, and CSS<br></br>
                • Led the development and deployment of BSM, a robust intranet platform used by
                Heineken for analysing and tracking key performance indicators (KPIs) and
                production statistics<br></br>
                • Demonstrated strong technical skills by successfully cleaning, migrating, and
                manipulating large datasets in SQL Server, ensuring accurate and efficient data
                management
                </Typography>
              </Box>
              <Box sx={{ width: '80%', textAlign: 'justify', mt: 2 }}>
                <Typography variant="h4" component="h4" >
                  Healthcare Assistant | Aug 2017 - Jan 2022
                </Typography>
                <Typography variant="body1" component="p" >
                • Exceptional patient care skills, adept in administering vaccinations, providing
routine health screenings, mental health assessments. Working effectively under
high-pressure conditions as part of a multidisciplinary healthcare team
                </Typography>
              </Box>
            </Box>
          </Box>
        )
}

 export default Experiences;

