export const projects = [
    {
      title: "Project 1",
      subtitle: "Project subtitle 1",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "https://static1.squarespace.com/static/58749b523a04117c40b519e5/58756026b3db2ba5306da98a/5b1f64a488251b24d2e94342/1546212482089/RC-Icon-Design.gif?format=1500w",
      link: "#",
    },
    {
      title: "Project 2",
      subtitle: "Project subtitle 2",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "https://cdn.dribbble.com/users/485616/screenshots/4741545/technology_anime_icon.gif",
      link: "#",
    },
    {
      title: "Project 3",
      subtitle: "Project subtitle 3",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "https://i.gifer.com/Vpsz.gif",
      link: "#",
    },
    {
      title: "Project 4",
      subtitle: "Project subtitle 4",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "https://images.squarespace-cdn.com/content/v1/5f904f0edc89546c89757b2b/1607056915169-SMSH9OTHT8XPFJK3RT7J/image-asset.gif",
      link: "#",
    },
  ];

