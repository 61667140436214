import { Box, Typography, Button } from "@mui/material";
import SchoolIcon from '@mui/icons-material/School';


const Education = () => {
    return (
        <Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            paddingTop: '2rem', 
            paddingBottom: '2rem'
          }}>
            <SchoolIcon className="mx-auto inline-block w-10 mb-4" />
              <Typography variant="h1" component="h1" sx={{fontSize:'3rem', marginBottom:'1rem'}} >Education</Typography>
              <Box sx={{ width: '80%', textAlign: 'justify' }}>
                <Typography variant="h4" component="h4" >
                  Healthcare Technologies KCL | MSc | Kings College London
                </Typography>
                <Typography variant="body1" component="p" >
                The master's experience encompassed key modules, with a particular focus on "Machine Learning for Biomedical Applications." The advanced research project delved into the intricate relationship between brain health and COVID-19 outcomes, utilizing deep learning techniques for the detection of cerebral MRI pathology. The application of PyTorch facilitated the processing, training, and inference of high-dimensional data. A notable challenge encountered during the research was effectively managing class imbalance, emphasizing the importance of robust strategies for skewed class distributions.
                <br></br>
                <br></br>
                In addition to this, the master's program included modules exploring various aspects of the brain. One such module delved into predicting brain age by incorporating features like cortical thickness and properties present in both white and grey matter. This exploration required extensive involvement in feature engineering, underscoring the significance of crafting meaningful features to enhance the accuracy and interpretability of the predictive models. This diverse set of modules provided a comprehensive understanding of machine learning applications in the biomedical field, incorporating both advanced research and practical challenges in the analysis of brain-related data.
                </Typography>
              </Box>
              <Box sx={{ width: '80%', textAlign: 'justify', mt: 2 }}>
                <Typography variant="h4" component="h4" >
                  Physics with Medical Applications | BSc | Kings College London
                </Typography>
                <Typography variant="body1" component="p" >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo
                    facilis repellat ab cupiditate alias vero aliquid obcaecati quisquam
                    fuga dolore.
                </Typography>
              </Box>
            </Box>
          </Box>
        )
}

 export default Education;

