import { Code } from "@mui/icons-material";
import React from "react";
import { projects } from "../assets/data";
import { Box, Typography } from "@mui/material";

const Projects = () => {
  return (
    <Box sx={{
      color: 'gray',
      backgroundColor: '#111827',
    }}>
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom:'2rem', marginTop:'2rem'}}>
          <Code className="mx-auto inline-block w-10 mb-4" />
            <Typography variant="h1" component="h1" color={'white'} sx={{fontSize:'3rem', marginBottom:'1rem'}} >Projects</Typography>
            <Typography variant="body1" component="p" >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo
              facilis repellat ab cupiditate alias vero aliquid obcaecati quisquam
              fuga dolore.
            </Typography>
        </Box>
        <div className="flex flex-wrap -m-4">
            {projects.map((project:any) => (
            <a
              href={project.link}
              key={project.image}
              className="sm:w-1/2 w-100 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </Box>
  );
}


export default Projects;