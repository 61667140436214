import leslie from "../assets/leslie-bike.png";
import { Box, Button, Typography } from "@mui/material";
import PortfolioLinks from "./PorfolioLinks";
import { Link } from "react-router-dom";


export default function About() {
  return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        px: 3, 
        py: 5,
        paddingTop: '110px',
        flexDirection: {
          xs: 'column',
          md: 'row'
        } }}>
        <Box sx={{px: 3, paddingX: {
          md: 8
        }}}>
            <Typography variant="h3" component="h3">Leslie Pan</Typography>
            <Box my={2}>
              <Typography variant="body1" component="p" fontWeight={600} marginBottom={2}>
                Machine Learning Engineer
              </Typography>
              <Typography variant="body1" component="p" textAlign={'justify'}>
                🚀 Welcome to Leslie's World of Machine Learning and Innovation! 🤖✨

As a passionate Machine Learning Engineer with a foundation in Software Development, I bring algorithms to life and turn data into actionable insights. When I'm not immersed in the world of coding, you'll find me navigating scenic cycling routes, shooting hoops on the basketball court, or diving into the virtual realms of Fortnite. Let's explore the intersection of artificial intelligence and creativity together. 🌐💡
              </Typography>
            </Box>
            <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start' }} my={2}>
              <Button component={Link} to="/404" variant="contained" sx={{ mr: 1 }} color="success">Contact Info</Button>
              <Button component={Link} to="/404" variant="contained" sx={{ mr: 1 }}>Check Out My Works</Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} my={2}>
              <PortfolioLinks></PortfolioLinks>
            </Box>
        </Box>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          marginBottom: { xs: 5, md: 0 },width: {
            xs: '100%',
            sm: '100%',
            md: '3000px'
        }}}>
          <img
            className="object-cover object-center rounded-full"
            alt="hero"
            src={leslie}
          />
        </Box>
      </Box>
  );
}