import { Box, Link, Typography } from "@mui/material";

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#000",
                color: "#9CA3AF",
                paddingY: "16px",
                paddingX: "45px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 'calc(10% + 60px)',
                position: 'fixed',
                bottom: 0,
                width: '100%'
            }}
        >
            <Typography sx={{ fontSize: "small", textAlign: "center", color: "#9CA3AF" }}>
                &copy; 2023 <a href="www.google.com" className="hover:underline text-decoration-none">Leslie's App</a>. All Rights Reserved.
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ marginRight: "6px", hover: "underline", fontSize: "small" }}>
                    <Link href='#' underline="hover" sx={{color:"#9CA3AF"}}>About</Link>
                </Typography>
                <Typography sx={{ marginRight: "6px", hover: "underline", fontSize: "small" }}>
                    <Link href='#' underline="hover" sx={{color:"#9CA3AF"}}>Privacy Policy</Link>   
                </Typography>
                <Typography sx={{ marginRight: "6px", hover: "underline", fontSize: "small" }}>
                    <Link href='#' underline="hover" sx={{color:"#9CA3AF"}}>Licensing</Link>
                </Typography>
                <Typography sx={{ hover: "underline", fontSize: "small" }}>
                    <Link href='#' underline="hover" sx={{color:"#9CA3AF"}}>Contact</Link>
                </Typography>
            </Box>
        </Box>)
}

 export default Footer;
