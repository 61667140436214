import { Box, Button } from '@mui/material';
import Footer from '../../components/Footer';
import Navbar from '../../components/NavigationBar/NavigationBar';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


const About = () => {
  return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        px: 3, 
        py: 5,
        paddingTop: '110px',
        flexDirection: {
          xs: 'column',
          md: 'row'
        } }}>
          <Button component={Link} to="/rr" variant="contained" sx={{ mr: 1 }} color="success">Click For Superpowers</Button>
      </Box>
  );
}
const Home = () => {
  const [navbarHidden, setNavbarHidden] = useState(true);
  const [scrollPos, setScrollPos] = useState(101);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const hide = scrollPos > currentScrollPos || currentScrollPos <= 250;
  
      setScrollPos(window.scrollY);
      setNavbarHidden(hide);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPos]);
  
  return (
    <Box className='bg-slate-300 min-h-screen'>
      <Navbar hidden={navbarHidden}/>
      <About />
      <Footer/>
    </Box>
  );
}

export default Home;
