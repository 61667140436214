import { Box } from '@mui/material';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Navbar from '../components/NavigationBar/NavigationBar';
import Projects from '../components/Projects';
import Skills from '../components/Skills';
import { useEffect, useState } from 'react';
import Experiences from '../components/Experience/Experiences';
import Education from '../components/Experience/Education';

const Home = () => {
  const [navbarHidden, setNavbarHidden] = useState(true);
  const [scrollPos, setScrollPos] = useState(101);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const hide = scrollPos > currentScrollPos || currentScrollPos <= 250;
  
      setScrollPos(window.scrollY);
      setNavbarHidden(hide);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPos]);
  
  return (
    <Box className='bg-slate-300 min-h-screen'>
      <Navbar hidden={navbarHidden}/>
      <About />
      <Experiences/>
      <Education/>
      <Projects />
      <Skills />
      <Box className='mb-6 pt-10 pb-10 flex justify-center text-gray-400 bg-gray-900 body-font' >
        <iframe title="Weather Forcast" src="https://www.euronews.com/embed/weather/europe/united-kingdom/london"  className="mx-auto"  style={{width:'70%', height:'500px'}}></iframe>
      </Box>  
      <Contact />
      <Footer/>
    </Box>
  );
}

export default Home;
