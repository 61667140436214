import { Box } from "@mui/material";
import Navbar from '../components/NavigationBar/NavigationBar';
import Footer from '../components/Footer';
import { useEffect, useRef, useState } from "react";


const Wmh = () => {
    const [navbarHidden, setNavbarHidden] = useState(true);
    const [scrollPos, setScrollPos] = useState(101);
    
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const hide = scrollPos > currentScrollPos || currentScrollPos <= 250;
    
        setScrollPos(window.scrollY);
        setNavbarHidden(hide);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [scrollPos]);
    
  return (
    <Box className='bg-slate-300 min-h-screen'>
      <Navbar hidden={navbarHidden}/>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'column', 
          alignItems: 'center',
          px: 3, 
          py: 5,
          paddingTop: '110px',
          flexDirection: {
            xs: 'column',
            md: 'row'
          } }}>
          <Box>Project in works - Please check back later</Box>
        </Box>
      <Footer />
    </Box>
  )
}

export default Wmh
