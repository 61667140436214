import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import nnBrain from "../../assets/nn_brain.png";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { Link } from 'react-router-dom';

const pages = [
  {
    name: "Thesis Project",
    path: "/wmh",
  },
  {
    name: "Projects",
    path: "/projects",
  },
  {
    name: "Contact Info",
    path: "/contact",
  }
];


const NavigationPages = () => {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Box component="img" sx={{ display: { xs: 'none', md: 'flex' }, height: 23, maxHeight: 23, mr: 1 }} src={nnBrain} alt="hero"></Box>
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="/"
        sx={{
          mr: 2,
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Homepage
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page?.name} onClick={handleCloseNavMenu} component={Link} to={page?.path}>
              <Typography textAlign="center">{page.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box component="img" sx={{ display: { xs: 'flex', md: 'none' }, height: 23, maxHeight: 23, mr: 1 }} src={nnBrain} alt="hero"></Box>
      <Typography
        variant="h5"
        noWrap
        component="a"
        href="/"
        sx={{
          mr: 2,
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Homepage
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {pages.map((page) => (
          <Button
            component={Link} to={page?.path}
            key={page?.name}
            onClick={handleCloseNavMenu}
            sx={{ my: 2, color: 'inherit', display: 'block' }}
          >
            {page?.name}
          </Button>
        ))}
      </Box>
    </>
  );
};

export default NavigationPages;
