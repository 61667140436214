import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Skills from './components/Skills';
import Wmh from './pages/Wmh';
import Test from './pages/misc/Test';
import RickRoll from './pages/misc/Rickroll'
import NotFound from './pages/NotFound';

const App = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/skills' element={<Skills />} />
            <Route path='/wmh' element={<Wmh />} />
            <Route path='/rr' element={<RickRoll />} />
            <Route path='/secret-page' element={<Test />} />
            <Route path="/secret" element={<Test />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
